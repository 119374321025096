import { useNotifications } from '@decisiv/ui-components';
import { useState } from 'react';
import { postMagicLinkService } from '../services';

const MAGIC_LINK_DEFAULT = {
  identity: null,
  dealer_id: '',
};

export default function useMagicLink(dealerId, csrfToken) {
  const [magicLinkBody, setMagicLinkBody] = useState(MAGIC_LINK_DEFAULT);
  const { notify } = useNotifications();

  async function postMagicLink() {
    try {
      const response = await postMagicLinkService(csrfToken, {
        ...magicLinkBody,
        dealer_id: dealerId,
      });

      await response.json();
      notify({
        id: 'notify-success',
        intent: 'success',
        title: 'Magic Link Sent',
      });
    } catch (error) {
      notify({
        id: 'notify-error',
        intent: 'danger',
        title: error,
      });
    }
  }

  function isDirty() {
    return !magicLinkBody.identity;
  }

  return {
    magicLinkBody,
    setMagicLinkBody,
    postMagicLink,
    isDirty,
  };
}
