import React from 'react';
import PropTypes from 'prop-types';

import { ConfigProvider } from '@decisiv/ui-components';

const AppConfigProvider = ({ children }) => {
  return <ConfigProvider>{children}</ConfigProvider>;
};

AppConfigProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AppConfigProvider;
