import React from 'react';

import { P } from '@decisiv/ui-components';
import useMagicLink from './hooks/useMagicLink';
import { propTypes, defaultProps, RequestForm } from './RequestForm';

function ExtraInfo() {
  return (
    <P
      weight="regular"
      shade={1}
      style={{
        fontSize: '12px',
        fontWeight: '500',
        textAlign: 'left',
        marginTop: '20px',
        backgroundColor: '#FFEBC8',
        padding: '10px',
        color: '#25262E'
      }}
    >
      If the mobile phone number or email address you supplied are associated
      with an existing account, we will send you a link.
    </P>
  );
}

function MagicLinkRequest({ dealerId, csrfToken, backLoginClick }) {
  const {
    magicLinkBody,
    setMagicLinkBody,
    postMagicLink,
    isDirty,
  } = useMagicLink(dealerId, csrfToken);

  const onChangeHandler = (e) => {
    setMagicLinkBody({
      ...magicLinkBody,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <RequestForm
      onChange={onChangeHandler}
      submitAction={postMagicLink}
      backAction={backLoginClick}
      isDirty={isDirty()}
      type="magic_link"
    >
      <ExtraInfo />
    </RequestForm>
  );
}

MagicLinkRequest.propTypes = propTypes;
MagicLinkRequest.defaultProps = defaultProps;

export default MagicLinkRequest;
