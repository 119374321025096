import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Button from '@decisiv/ui-components/lib/components/Button';
import Modal from '@decisiv/ui-components/lib/components/Modal';

import Eye from '@decisiv/iconix/lib/components/Eye';

const HelloWorld = ({ greeting }) => {
  const [visible, setVisible] = useState(false);
  const toggle = () => setVisible(!visible);
  return (
    <>
      <Button text="Click here" onClick={toggle} />
      <Modal
        actions={[
          { text: 'Primary', onClick: toggle },
          { text: 'Secondary', onClick: toggle },
        ]}
        intent="success"
        onClose={toggle}
        title="Modal Title"
        visible={visible}
        icon={Eye}
      >
        <div>{greeting}</div>
      </Modal>
    </>
  );
};

HelloWorld.propTypes = {
  greeting: PropTypes.string,
};

HelloWorld.defaultProps = {
  greeting: 'Greetings!',
};

export default HelloWorld;
