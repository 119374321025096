import React from 'react';
import PropTypes from 'prop-types';

import { H4, P } from '@decisiv/ui-components';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import NotificationToggle from './NotificationToggle';

function ToggleablePreferenceRow({ kind, user, model, smsCommunicationDisabled }) {
  return (
    <Flex flexDirection="column">
      <H4 style={{ fontWeight: '800' }}>{kind.title}</H4>
      <P style={{ fontSize: '12px', color: '#737482', marginTop: '0px' }}>
        {kind.subtitle}
      </P>
      {kind.channels.map((channel, index) => (
        <NotificationToggle
          key={`${channel.name}`}
          model={model}
          user={user}
          channel={channel}
          index={index}
          kind={kind.name}
          smsCommunicationDisabled={smsCommunicationDisabled}
        />
      ))}
    </Flex>
  );
}

ToggleablePreferenceRow.propTypes = {
  kind: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    channels: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        title: PropTypes.string,
        value: PropTypes.string,
      }),
    ),
  }).isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    csrfToken: PropTypes.string,
  }).isRequired,
  model: PropTypes.string.isRequired,
  smsCommunicationDisabled: PropTypes.bool.isRequired,
};

export default ToggleablePreferenceRow;
