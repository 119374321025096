/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button } from '@decisiv/ui-components';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import ArrowRight from '@decisiv/iconix/lib/components/ArrowRight';
import toColorString from 'polished/lib/color/toColorString';
import color from '@decisiv/design-tokens/lib/color';
import Checkmark from '@decisiv/iconix/lib/components/CheckCircleF';
import Warning from '@decisiv/iconix/lib/components/ExclamationTriangleF';
import PasswordField from '@decisiv/ui-components/lib/components/PasswordField';
import {
  RequirementDiv,
  RequirementTitleDiv,
  Paragraph,
  ExtraInfoDiv,
} from './components/styledComponents';
import usePassword from './hooks/usePassword';
import { Container, HelperText } from './RequestForm';

function ExtraInfo({ passwordBody, validLength, validCaps, validDigit }) {
  const displayIcon =
    passwordBody.password1 !== '' || passwordBody.password2 !== '';
  const iconPass = {
    alignSelf: 'center',
    paddingRight: '5px',
    color: '#11BC6E',
    width: '25px',
  };
  const iconFail = {
    alignSelf: 'center',
    paddingRight: '5px',
    color: '#FFA300',
    width: '25px',
  };

  return (
    <ExtraInfoDiv>
      <RequirementTitleDiv>Password Requirements:</RequirementTitleDiv>
      <RequirementDiv>
        {displayIcon && validLength && <Checkmark style={iconPass} />}
        {displayIcon && !validLength && <Warning style={iconFail} />}
        {!displayIcon && <Paragraph>—</Paragraph>}
        {'At least 8 characters — the more characters, the better.'}
      </RequirementDiv>
      <RequirementDiv>
        {displayIcon && validCaps && <Checkmark style={iconPass} />}
        {displayIcon && !validCaps && <Warning style={iconFail} />}
        {!displayIcon && <Paragraph>—</Paragraph>}
        {'A mixture of both uppercase and lowercase letters.'}
      </RequirementDiv>
      <RequirementDiv>
        {displayIcon && validDigit && <Checkmark style={iconPass} />}
        {displayIcon && !validDigit && <Warning style={iconFail} />}
        {!displayIcon && <Paragraph>—</Paragraph>}
        {'A mixture of letters and numbers.'}
      </RequirementDiv>
    </ExtraInfoDiv>
  );
}

function Password({ dealerId, csrfToken }) {
  const {
    passwordBody,
    setPasswordBody,
    postPasswordChange,
    isValidMatch,
    isValidLength,
    isValidCaps,
    isValidDigit,
  } = usePassword(dealerId, csrfToken);

  const [validMatch, setValidMatch] = useState(true);
  const [validLength, setValidLength] = useState(false);
  const [validCaps, setValidCaps] = useState(false);
  const [validDigit, setValidDigit] = useState(false);

  useEffect(() => {
    setValidMatch(isValidMatch());
    setValidLength(isValidLength());
    setValidCaps(isValidCaps());
    setValidDigit(isValidDigit());
  }, [isValidCaps, isValidDigit, isValidLength, isValidMatch, passwordBody]);

  const errorMatchStyle = { color: 'red' };

  const onChangeHandler = (e) => {
    setPasswordBody({
      ...passwordBody,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Container title="Change Password">
      <HelperText message="Choose a new password to complete your reset password request." />
      <Flex
        flexDirection="column"
        style={{ backgroundColor: toColorString(color.opacity.fullMoon50) }}
        padding={2}
      >
        <PasswordField
          style={{ marginBottom: '10px' }}
          // label="New Password"
          name="password1"
          onChange={onChangeHandler}
        />
        <PasswordField
          // label="New Password"
          name="password2"
          onChange={onChangeHandler}
        />
        {!validMatch && (
          <div style={errorMatchStyle}>The passwords do not match!</div>
        )}
        <Flex justifyContent="space-between" marginTop={1}>
          <Button
            icon={ArrowRight}
            iconPosition="right"
            text="Change Password"
            kind="primary"
            onClick={() => postPasswordChange()}
            disabled={!validMatch || !validLength || !validCaps || !validDigit}
          />
        </Flex>
      </Flex>
      <ExtraInfo
        validLength={validLength}
        validCaps={validCaps}
        validDigit={validDigit}
        passwordBody={passwordBody}
      />
    </Container>
  );
}

Password.propTypes = {
  dealerId: PropTypes.string,
  csrfToken: PropTypes.string.isRequired,
};

Password.defaultProps = {
  dealerId: '',
};

export default Password;
