import { useState } from 'react';
import { postSetMobile } from '../services';

function useSetMobile(userId, csrfToken, sucessCallback, failCallback) {
  const [mobile, setMobile] = useState('');
  const [countryCode, setCountryCode] = useState('');

  async function postMobile() {
    try {
      const response = await postSetMobile(
        userId,
        csrfToken,
        mobile,
        countryCode,
      );

      await response.json();
      sucessCallback();
    } catch (error) {
      failCallback(mobile);
    }
  }

  function isDirty() {
    return !(mobile && countryCode);
  }

  return {
    mobile,
    setMobile,
    countryCode,
    setCountryCode,
    postMobile,
    isDirty,
  };
}

export default useSetMobile;
