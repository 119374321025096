/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Button, H1, P } from '@decisiv/ui-components';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import PasswordField from '@decisiv/ui-components/lib/components/PasswordField';
import UserArrowRight from '@decisiv/iconix/lib/components/UserArrowRight';
import Key from '@decisiv/iconix/lib/components/Key';
import ArrowRight from '@decisiv/iconix/lib/components/ArrowRight';
import toColorString from 'polished/lib/color/toColorString';
import color from '@decisiv/design-tokens/lib/color';
import useLogin from './hooks/useLogin';
import { InputField } from './RequestForm';

function ExtraInfo() {
  return (
    <P
      weight="regular"
      shade={1}
      style={{
        fontSize: '12px',
        textAlign: 'center',
        marginTop: '35px',
        fontWeight: '400',
      }}
    >
      By logging into the application you agree to the Policies & Agreements.
      You can view these agreements{' '}
      <a
        href="https://support.dsmp.decisiv.net/hc/en-us/articles/360011094554"
        style={{ textDecoration: 'underline' }}
      >
        {' '}
        here
      </a>
      .
    </P>
  );
}

function PortalLogin({
  dealerId,
  csrfToken,
  magicLinkClick,
  requestPasswordClick,
}) {
  const { loginBody, setLoginBody, postLogin, isDirty } = useLogin(
    dealerId,
    csrfToken,
  );

  const onChangeHandler = (e) => {
    setLoginBody({
      ...loginBody,
      [e.target.name]: e.target.value,
    });
  };

  const ButtonsContainer = styled(Flex)`
    justify-content: space-between;
    margin-top: -1px;

    @media screen and (max-width: 320px) {
      flex-direction: column-reverse;

      .btn__element--login-btn {
        margin-bottom: 15px;
      }
    }
  `;

  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      style={{ minHeight: '14px', margin: '0' }}
    >
      <Flex
        flexDirection="column"
        style={{ maxWidth: '390px', marginTop: '-6px' }}
      >
        <H1 style={{ padding: '0 20px' }}>Portal Login</H1>
        <Flex
          flexDirection="column"
          style={{ backgroundColor: toColorString(color.opacity.fullMoon50) }}
          padding={2}
        >
          <InputField
            icon={UserArrowRight}
            label="Username, email or mobile phone"
            name="identity"
            onChange={onChangeHandler}
          />
          <PasswordField
            style={{ marginBottom: '10px' }}
            icon={Key}
            name="password"
            onChange={onChangeHandler}
            autoComplete="off"
          />
          <ButtonsContainer>
            <div>
              <Button
                text="Login with magic link"
                kind="secondary"
                size="small"
                style={{ marginTop: '4px' }}
                onClick={() => magicLinkClick()}
              />
            </div>
            <div className="btn__element--login-btn">
              <Button
                icon={ArrowRight}
                text="Login"
                iconPosition="right"
                onClick={() => postLogin()}
                disabled={isDirty()}
              />
            </div>
          </ButtonsContainer>
          <Flex marginTop={2}>
            <a
              role="button"
              tabIndex={0}
              onClick={() => requestPasswordClick()}
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
            >
              Forgot password
            </a>
          </Flex>
        </Flex>
        <ExtraInfo />
      </Flex>
    </Flex>
  );
}

PortalLogin.propTypes = {
  dealerId: PropTypes.string,
  csrfToken: PropTypes.string.isRequired,
  magicLinkClick: PropTypes.func,
  requestPasswordClick: PropTypes.func,
};

PortalLogin.defaultProps = {
  dealerId: '',
  magicLinkClick: () => {},
  requestPasswordClick: () => {},
};

export default PortalLogin;
