import React from 'react';
import PropTypes from 'prop-types';
import { Flex } from '@decisiv/ui-components';
import { Padding } from './styledComponents';
import { REQUEST_EMAIL, REQUEST_MOBILE } from '../constants';
import EmailRequestForm from './EmailRequestForm';
import MobileRequestForm from './MobileRequestForm';

function RequestScreen({
  csrfToken,
  requestType,
  portalName,
  onContinue,
  onContinueNoPost,
  onFail,
  userId,
  countryCodeOptions,
}) {
  return (
    <Padding>
      <Flex flexDirection="column">
        {requestType === REQUEST_EMAIL && (
          <EmailRequestForm
            csrfToken={csrfToken}
            onContinue={onContinue}
            onContinueNoPost={onContinueNoPost}
            onFail={onFail}
            userId={userId}
          />
        )}
        {requestType === REQUEST_MOBILE && (
          <MobileRequestForm
            csrfToken={csrfToken}
            onContinue={onContinue}
            onContinueNoPost={onContinueNoPost}
            onFail={onFail}
            userId={userId}
            countryCodeOptions={countryCodeOptions}
          />
        )}
      </Flex>
    </Padding>
  );
}

RequestScreen.propTypes = {
  userId: PropTypes.number.isRequired,
  csrfToken: PropTypes.string.isRequired,
  requestType: PropTypes.string.isRequired,
  onContinue: PropTypes.func.isRequired,
  onContinueNoPost: PropTypes.func.isRequired,
  onFail: PropTypes.func.isRequired,
  portalName: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  countryCodeOptions: PropTypes.array,
};

RequestScreen.defaultProps = {
  countryCodeOptions: [],
};

export default RequestScreen;
