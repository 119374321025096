import React from 'react';
import PropTypes from 'prop-types';
import { Button, P, Link } from '@decisiv/ui-components';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import toColorString from 'polished/lib/color/toColorString';
import color from '@decisiv/design-tokens/lib/color';
import ArrowRight from '@decisiv/iconix/lib/components/ArrowRight';
import ArrowLeft from '@decisiv/iconix/lib/components/ArrowLeft';
import { InstructionsDiv, Padding } from './styledComponents';
import { REQUEST_EMAIL } from '../constants';

function ConflictScreen({ requestType, value, onBack, onContinue }) {
  const requestText =
    requestType === REQUEST_EMAIL ? 'email address' : 'mobile phone number';

  return (
    <Padding>
      <P
        style={{
          fontSize: '16px',
          color: toColorString(color.base.alaskanHusky),
        }}
      >
        The {requestText}{' '}
        <b style={{ color: toColorString(color.base.charcoal) }}>{value}</b> you
        provided is{' '}
        <b style={{ color: toColorString(color.base.charcoal) }}>
          already being used by another contact
        </b>{' '}
        on our platform.
      </P>
      <P
        style={{
          fontWeight: 400,
          color: toColorString(color.base.alaskanHusky),
        }}
      >
        Unfortunately we will not be able to add this {requestText} to your
        account right now.
      </P>
      <P
        style={{
          fontWeight: 400,
          color: toColorString(color.base.alaskanHusky),
        }}
      >
        Please contact the Service Advisor to make this update.
      </P>
      <Flex marginTop={2}>
        <Button
          icon={ArrowRight}
          text="Continue to Portal"
          iconPosition="right"
          onClick={() => onContinue()}
        />
      </Flex>
      <Flex marginTop={2}>
        <Button
          icon={ArrowLeft}
          text="Previous Page"
          iconPosition="left"
          kind="secondary"
          onClick={() => onBack()}
        />
      </Flex>
      <Flex marginTop={4} flexDirection="column">
        <InstructionsDiv>
          <P
            style={{
              fontWeight: 600,
            }}
          >
            Why am I seeing this?{' '}
          </P>
          <P
            style={{
              fontWeight: 400,
            }}
          >
            You are probably seeing this because you already have an account in
            our platform. We are currently working in a way to merge different
            accounts that belongs to the same person. You can understand more
            about this in the following link.
          </P>
        </InstructionsDiv>
      </Flex>
      <Flex marginTop={2}>
        <Link to="/" size="small" text="Help Desk - Multiple Accounts" />
      </Flex>
    </Padding>
  );
}

ConflictScreen.propTypes = {
  requestType: PropTypes.string.isRequired,
  onBack: PropTypes.func.isRequired,
  onContinue: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default ConflictScreen;
