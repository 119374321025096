import React, { useState } from 'react';

import { NotificationsPanel } from '@decisiv/ui-components';
import PortalLogin from './PortalLogin';
import MagicLinkRequest from './MagicLinkRequest';
import PasswordReset from './PasswordReset';
import Password from './Password';
import { propTypes, defaultProps } from './RequestForm';

function Login({ dealerId, csrfToken, defaultScreen = 'PortalLogin' }) {
  const PORTAL_LOGIN = 'PortalLogin';
  const MAGIC_LINK_REQUEST = 'MagicLinkRequest';
  const PASSWORD_RESET = 'PasswordReset';
  const PASSWORD = 'Password';

  const [screen, setScreen] = useState(defaultScreen);

  return (
    <div>
      <NotificationsPanel marginTop={4} zIndex={1000} />

      {screen === PORTAL_LOGIN && (
        <PortalLogin
          dealerId={dealerId}
          csrfToken={csrfToken}
          requestPasswordClick={() => setScreen(PASSWORD_RESET)}
          magicLinkClick={() => setScreen(MAGIC_LINK_REQUEST)}
        />
      )}
      {screen === MAGIC_LINK_REQUEST && (
        <MagicLinkRequest
          dealerId={dealerId}
          csrfToken={csrfToken}
          backLoginClick={() => setScreen(PORTAL_LOGIN)}
        />
      )}
      {screen === PASSWORD_RESET && (
        <PasswordReset
          csrfToken={csrfToken}
          backLoginClick={() => setScreen(PORTAL_LOGIN)}
        />
      )}
      {screen === PASSWORD && (
        <Password
          csrfToken={csrfToken}
          backLoginClick={() => setScreen(PORTAL_LOGIN)}
        />
      )}
    </div>
  );
}

Login.propTypes = propTypes;
Login.defaultProps = defaultProps;

export default Login;
