/* eslint-disable import/prefer-default-export */
export async function postLoginService(csrfToken, loginPayload) {
  const loginUrl = '/authentication/sessions.json';

  const response = await fetch(loginUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'X-CSRF-Token': csrfToken,
    },
    body: JSON.stringify(loginPayload),
  });

  if (!response.ok) {
    const parsedResponse = await response.json();
    throw parsedResponse.message;
  }
  return response;
}

/* eslint-disable import/prefer-default-export */
export async function postMagicLinkService(csrfToken, magicLinkPayload) {
  const magiLinkUrl = '/authentication/magic_links/generate.json';

  const response = await fetch(magiLinkUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'X-CSRF-Token': csrfToken,
    },
    body: JSON.stringify(magicLinkPayload),
  });

  if (!response.ok) {
    const parsedResponse = await response.json();
    throw parsedResponse.message;
  }
  return response;
}

export async function postPasswordService(
  csrfToken,
  type,
  identity,
  countryCode,
) {
  const passwordResetUrl = '/authentication/password_resets.json';

  const response = await fetch(passwordResetUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'X-CSRF-Token': csrfToken,
    },
    body: JSON.stringify({ type, identity, country_code: countryCode }),
  });

  if (!response.ok) {
    const parsedResponse = await response.json();
    throw parsedResponse.message;
  }
  return response;
}

export async function postPasswordChangeService(csrfToken, passwordPayload) {
  const passwordUrl = '/authentication/passwords.json';

  const response = await fetch(passwordUrl, {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'X-CSRF-Token': csrfToken,
    },
    body: JSON.stringify(passwordPayload),
  });

  if (!response.ok) {
    const parsedResponse = await response.json();
    throw parsedResponse.message;
  }
  return response;
}
