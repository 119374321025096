import { useNotifications } from '@decisiv/ui-components';
import { useState } from 'react';
import { postLoginService } from '../services';

const LOGIN_BODY_DEFAULT = {
  identity: null,
  password: null,
  dealer_id: '',
};

export default function useLogin(dealerId, csrfToken) {
  const [loginBody, setLoginBody] = useState(LOGIN_BODY_DEFAULT);
  const { notify } = useNotifications();

  async function postLogin() {
    try {
      const response = await postLoginService(csrfToken, {
        ...loginBody,
        dealer_id: dealerId,
      });

      const parsedResponse = await response.json();
      window.location.href = parsedResponse.return_to || '/';
    } catch (error) {
      notify({
        id: 'notify-error',
        intent: 'danger',
        title: error,
      });
    }
  }

  function isDirty() {
    return !(loginBody.identity && loginBody.password);
  }

  return {
    loginBody,
    setLoginBody,
    postLogin,
    isDirty,
  };
}
