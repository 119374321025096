import { render } from '@testing-library/react';
import React from 'react';
import HelloWorld from './HelloWorld';

describe('HelloWorld', () => {
  it('render the button', () => {
    const { getByText } = render(<HelloWorld greeting="Hello World" />);

    expect(getByText('Click here')).toBeInTheDocument();
  });
});
