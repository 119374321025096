import { useState } from 'react';
import { useNotifications } from '@decisiv/ui-components';
import { postSetPassword } from '../services';

function useSetPassword(userId, csrfToken, sucessCallback) {
  const [password, setPassword] = useState('');
  const [checkPassword, setCheckPassword] = useState('');
  const { notify } = useNotifications();

  async function postPassword() {
    try {
      const response = await postSetPassword(userId, csrfToken, password);

      await response.json();
      sucessCallback();
    } catch (error) {
      notify({
        id: 'notify-error',
        intent: 'danger',
        title: error,
      });
    }
  }

  function isDirty() {
    return !(password && checkPassword && password === checkPassword);
  }

  return {
    password,
    setPassword,
    checkPassword,
    setCheckPassword,
    postPassword,
    isDirty,
  };
}

export default useSetPassword;
