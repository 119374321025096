/* eslint-disable no-console */
/* eslint-disable vars-on-top */
/* eslint-disable no-undef */
/* eslint-disable no-var */
/* eslint-disable react/no-render-return-value */
import ReactDOM from 'react-dom';
import React from 'react';
import ConfigProvider from '../components/ConfigProvider';

function supportsHydration() {
  return (
    typeof ReactDOM.hydrate === 'function' ||
    typeof ReactDOM.hydrateRoot === 'function'
  );
}

function reactHydrate(node, component) {
  if (typeof ReactDOM.hydrateRoot === 'function') {
    return ReactDOM.hydrateRoot(node, component);
  }
  return ReactDOM.hydrate(component, node);
}

// Support for the new `createRoot` API introduced with React 18
function createReactRootLike(node) {
  return ReactDOM.createRoot
    ? ReactDOM.createRoot(node)
    : legacyReactRootLike(node);
}

function legacyReactRootLike(node) {
  const root = {
    render(component) {
      return ReactDOM.render(component, node);
    },
  };
  return root;
}

// This functions wraps the component from UJS into the `ContextProvider` component
export default function mountComponentsWithContext(searchSelector) {
  var ujs = ReactRailsUJS;
  var nodes = ujs.findDOMNodes(searchSelector);

  // eslint-disable-next-line no-plusplus
  for (var i = 0; i < nodes.length; i++) {
    var node = nodes[i];
    var className = node.getAttribute(ujs.CLASS_NAME_ATTR);
    var constructor = ujs.getConstructor(className);
    var propsJson = node.getAttribute(ujs.PROPS_ATTR);
    var props = propsJson && JSON.parse(propsJson);
    var hydrate = node.getAttribute(ujs.RENDER_ATTR);
    var cacheId = node.getAttribute(ujs.CACHE_ID_ATTR);
    var turbolinksPermanent = node.hasAttribute(ujs.TURBOLINKS_PERMANENT_ATTR);

    if (!constructor) {
      var message = `Cannot find component: '${className}'`;
      if (console && console.log) {
        console.log(
          `%c[react-rails] %c${message} for element`,
          'font-weight: bold',
          '',
          node,
        );
      }
      throw new Error(
        `${message}. Make sure your component is available to render.`,
      );
    } else {
      var component = this.components[cacheId];
      if (component === undefined) {
        component = React.createElement(constructor, props);
        if (turbolinksPermanent) {
          this.components[cacheId] = component;
        }
      }

      if (hydrate && supportsHydration()) {
        component = reactHydrate(node, component);
      } else {
        const root = createReactRootLike(node);
        component = root.render(<ConfigProvider>{component}</ConfigProvider>);
      }
    }
  }
}
