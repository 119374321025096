import styled from 'styled-components';

export const ExtraInfoDiv = styled.div`
  font-size: 15px;
  text-align: justify;
  margin-top: 35px;
  color: #737482;
`;

export const RequirementDiv = styled.div`
  padding-bottom: 15px;
  display: flex;
  align-items: center;
`;

export const RequirementTitleDiv = styled.div`
  color: #000000;
  padding-bottom: 15px;
  font-size: 17px;
`;

export const Paragraph = styled.span`
  display: '';
  padding-right: 5px;
`;
