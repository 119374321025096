import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NotificationsPanel } from '@decisiv/ui-components';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import {
  ResponsiveFlex,
  ResponsiveH2,
  Strong,
} from './components/styledComponents';
import {
  REQUEST_SCREEN,
  SUCCESS_SCREEN,
  CONFLICT_SCREEN,
  REQUEST_EMAIL,
  REQUEST_MOBILE,
  NO_REQUEST,
} from './constants';
import WelcomeScreen from './components/WelcomeScreen';
import RequestScreen from './components/RequestScreen';
import ConflictScreen from './components/ConflictScreen';

function Onboarding({
  userId,
  csrfToken,
  portalName,
  hasEmail,
  hasMobile,
  targetUrl,
  countryCodeOptions,
  realUser,
}) {
  const onContinueToPortal = () => {
    window.location = targetUrl;
  };

  const initType = () => {
    const requestMobile = !hasMobile ? REQUEST_MOBILE : NO_REQUEST;

    return !hasEmail ? REQUEST_EMAIL : requestMobile;
  };

  const [type, setType] = useState(initType());

  const [screen, setScreen] = useState(
    type === NO_REQUEST ? SUCCESS_SCREEN : REQUEST_SCREEN,
  );

  const [dataSended, setDataSended] = useState('');

  const showConflictScreen = (data) => {
    setDataSended(data);
    setScreen(CONFLICT_SCREEN);
  };

  const showSuccessScreen = () => setScreen(SUCCESS_SCREEN);
  const showRequestScreen = () => setScreen(REQUEST_SCREEN);

  const showSuccessScreenNoRequest = (data) => {
    setType(NO_REQUEST);
    setScreen(SUCCESS_SCREEN);
  };

  return (
    <div>
      <NotificationsPanel marginTop={4} zIndex={1000} />
      <Flex
        justifyContent="center"
        alignItems="start"
        style={{ minHeight: '80vh' }}
      >
        <ResponsiveFlex flexDirection="column">
          <ResponsiveH2>
            <Strong>{portalName}</Strong>
          </ResponsiveH2>

          {screen === REQUEST_SCREEN && (
            <RequestScreen
              requestType={type}
              portalName={portalName}
              onContinue={showSuccessScreen}
              onContinueNoPost={showSuccessScreenNoRequest}
              onFail={showConflictScreen}
              csrfToken={csrfToken}
              userId={userId}
              countryCodeOptions={countryCodeOptions}
            />
          )}
          {screen === SUCCESS_SCREEN && (
            <WelcomeScreen
              requestType={type}
              portalName={portalName}
              onContinue={targetUrl ? onContinueToPortal : null}
              realUser={realUser}
            />
          )}
          {screen === CONFLICT_SCREEN && (
            <ConflictScreen
              requestType={type}
              onContinue={showSuccessScreenNoRequest}
              onBack={showRequestScreen}
              value={dataSended}
            />
          )}
        </ResponsiveFlex>
      </Flex>
    </div>
  );
}

Onboarding.propTypes = {
  userId: PropTypes.number.isRequired,
  csrfToken: PropTypes.string.isRequired,
  portalName: PropTypes.string.isRequired,
  hasEmail: PropTypes.bool.isRequired,
  hasMobile: PropTypes.bool.isRequired,
  targetUrl: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  countryCodeOptions: PropTypes.array,
  realUser: PropTypes.bool,
};

Onboarding.defaultProps = {
  countryCodeOptions: [],
  targetUrl: null,
  realUser: false,
};

export default Onboarding;
