import { useNotifications } from '@decisiv/ui-components';
import { useState } from 'react';
import { postPasswordChangeService } from '../services';

const PASSWORD_DEFAULT = {
  password1: '',
  password2: '',
};

export default function usePassword(dealerId, csrfToken) {
  const [passwordBody, setPasswordBody] = useState(PASSWORD_DEFAULT);
  const { notify } = useNotifications();

  async function postPasswordChange() {
    try {
      const response = await postPasswordChangeService(csrfToken, {
        ...passwordBody,
        dealer_id: dealerId,
      });

      const parsedResponse = await response.json();
      window.location.href = parsedResponse.return_to || '/';
    } catch (error) {
      notify({
        id: 'notify-error',
        intent: 'danger',
        title: error,
      });
    }
  }

  function isValidMatch() {
    return passwordBody.password1 === passwordBody.password2;
  }

  function isValidLength() {
    const validate = (password) => password.length >= 8;

    return validate(passwordBody.password1) && validate(passwordBody.password2);
  }

  function isValidCaps() {
    const validate = (password) => {
      return password.match(new RegExp('([A-Z].*[a-z]|[a-z].*[A-Z])')) !== null;
    };

    return validate(passwordBody.password1) && validate(passwordBody.password2);
  }

  function isValidDigit() {
    const validate = (password) => password.match(/\d/) !== null;

    return validate(passwordBody.password1) && validate(passwordBody.password2);
  }

  return {
    passwordBody,
    setPasswordBody,
    postPasswordChange,
    isValidMatch,
    isValidLength,
    isValidCaps,
    isValidDigit,
  };
}
