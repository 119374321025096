import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { H4 } from '@decisiv/ui-components';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import Toggle from '@decisiv/ui-components/lib/components/Toggle';
import styled from 'styled-components';

const options = [
  { id: 'disabled', text: 'Off' },
  { id: 'enabled', text: 'On' },
];

const Divider = styled.div`
  width: 96.5%;
  height: 1px;
  margin-top: 2.5px;
  margin-bottom: 10.5px;
  background: rgba(37, 38, 46, 0.15);
`;

const StyledToggle = styled(Toggle)`
  &:hover div div div div {
    background-color: ${(props) =>
      props.disabled ? '#c5cad3 !important' : '#1c6ee6 !important'};
  }
`;

const savePreference = async (kind, channelName, value, model, user) => {
  const requestOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'X-CSRF-Token': user.csrfToken,
    },
  };

  const uri = `/mobilecomm/users/update_notifications/${user.id}`;

  const response = await fetch(uri, {
    ...requestOptions,
    body: JSON.stringify({
      notification_preferences: {
        model,
        name: kind,
        channel: {
          name: channelName,
          value,
        },
      },
    }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }
  return response;
};

function NotificationToggle({
  model,
  kind,
  channel,
  index,
  user,
  smsCommunicationDisabled,
}) {
  const [disabled, setDisabled] = useState(
    channel.name === 'sms' && smsCommunicationDisabled,
  );

  return (
    <div key={`div-${channel.name}`}>
      {!(index % 2 === 0) && <Divider />}
      <Flex key={`flex-${channel.name}`}>
        <H4 key={channel.name}>{channel.title}</H4>
        <StyledToggle
          style={{ marginLeft: 'auto' }}
          options={options}
          onChange={(value) => {
            setDisabled(true);
            savePreference(kind, channel.name, value, model, user).then(() => {
              setDisabled(false);
            });
          }}
          defaultSelected={channel.value}
          name="sms-toggle"
          size="small"
          key={`${channel.name}-${kind}`}
          disabled={disabled}
        />
      </Flex>
    </div>
  );
}

NotificationToggle.propTypes = {
  model: PropTypes.string.isRequired,
  kind: PropTypes.string.isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    csrfToken: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  channel: PropTypes.shape({
    name: PropTypes.string,
    title: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  smsCommunicationDisabled: PropTypes.bool.isRequired,
};

export default NotificationToggle;
