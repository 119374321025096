import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import toColorString from 'polished/lib/color/toColorString';

import color from '@decisiv/design-tokens/lib/color';

import Flex from '@decisiv/ui-components/lib/components/Flex';
import Grid from '@decisiv/ui-components/lib/components/Grid';
import { P } from '@decisiv/ui-components/lib/components/Typography';

import InfoCircle from '@decisiv/iconix/lib/components/InfoCircle';
import ExclamationCircle from '@decisiv/iconix/lib/components/ExclamationCircle';
import ExclamationTriangle from '@decisiv/iconix/lib/components/ExclamationTriangle';

const colorsMedium = {
  information: toColorString(color.status.information.medium),
  warning: toColorString(color.status.warning.medium),
  danger: toColorString(color.status.danger.medium),
};

const colorsLight = {
  information: toColorString(color.status.information.light),
  warning: toColorString(color.status.warning.light),
  danger: toColorString(color.status.danger.light),
};

const icons = {
  information: InfoCircle,
  warning: ExclamationCircle,
  danger: ExclamationTriangle,
};

const Container = styled(Flex)`
  border-left: 2px solid;
  border-color: ${(props) =>
    colorsMedium[props.type] || colorsMedium.information};
  background-color: ${(props) =>
    colorsLight[props.type] || colorsLight.information};

  border-radius: 2px;
`;

function WarningMessage({ intent, title, subtitle }) {
  const iconColor = colorsMedium[intent] || colorsMedium.information;
  const Icon = icons[intent] || icons.information;

  return (
    <Container
      marginBottom={2}
      marginTop={1}
      paddingX={1.5}
      paddingY={1.3}
      type={intent}
    >
      <Grid.Container>
        <Grid.Row>
          <Grid.Column css={{ maxWidth: '30px' }} padding={0}>
            <Icon color={iconColor} />
          </Grid.Column>
          <Grid.Column span="11" padding={0}>
            <P style={{ marginTop: '-2px' }} weight="semibold">
              {title}
            </P>
            <P style={{ fontSize: '12px', marginTop: '-10px' }} shade={1}>
              {subtitle}
            </P>
          </Grid.Column>
        </Grid.Row>
      </Grid.Container>
    </Container>
  );
}

WarningMessage.propTypes = {
  intent: PropTypes.oneOf(['warning', 'info']),
  title: PropTypes.string,
  subtitle: PropTypes.node,
};

WarningMessage.defaultProps = {
  intent: 'warning',
  title: '',
  subtitle: '',
};

export default WarningMessage;
