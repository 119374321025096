/* eslint-disable import/prefer-default-export */
export async function postSetEmail(userId, csrfToken, email) {
  const setEmailUrl = '/mobilecomm/set_email.json';

  const response = await fetch(setEmailUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'X-CSRF-Token': csrfToken,
    },
    body: JSON.stringify({ userId, email }),
  });

  if (!response.ok) {
    const parsedResponse = await response.json();
    throw parsedResponse.message;
  }
  return response;
}

export async function postSetMobile(userId, csrfToken, mobile, countryCode) {
  const setMobileUrl = '/mobilecomm/set_mobile.json';

  const response = await fetch(setMobileUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'X-CSRF-Token': csrfToken,
    },
    body: JSON.stringify({ userId, mobile, countryCode }),
  });

  if (!response.ok) {
    const parsedResponse = await response.json();
    throw parsedResponse.message;
  }
  return response;
}
