/* eslint-disable import/prefer-default-export */
export async function postSetPassword(userId, csrfToken, password) {
  const setPasswordUrl = '/mobilecomm/set_password.json';

  const response = await fetch(setPasswordUrl, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      'X-CSRF-Token': csrfToken,
    },
    body: JSON.stringify({ userId, password }),
  });

  if (!response.ok) {
    const parsedResponse = await response.json();
    throw parsedResponse.message;
  }
  return response;
}
