import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Button } from '@decisiv/ui-components';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import ArrowRight from '@decisiv/iconix/lib/components/ArrowRight';
import { InstructionsDiv, Strong } from './styledComponents';
import useSetEmail from '../hooks/useSetEmail';

function EmailRequestForm({
  userId,
  csrfToken,
  onContinue,
  onContinueNoPost,
  onFail,
}) {
  const { setEmail, postEmail, isDirty } = useSetEmail(
    userId,
    csrfToken,
    onContinue,
    onFail,
  );
  return (
    <>
      <InstructionsDiv>
        Provide a valid email address to be used in your portal communications.
      </InstructionsDiv>
      <Flex marginBottom={1} flexDirection="column">
        <Strong style={{ marginBottom: 10 }}>Email Address</Strong>
        <TextField
          label="Email"
          name="Email"
          type="Email"
          onChange={(e) => setEmail(e.target.value)}
        />
      </Flex>
      <Flex marginTop={1}>
        <Button
          style={{ marginRight: '10px' }}
          icon={ArrowRight}
          text="ADD EMAIL ADDRESS"
          iconPosition="right"
          disabled={isDirty()}
          onClick={() => postEmail()}
        />
      </Flex>
      <Flex marginTop={1}>
        <Button
          text="Continue to portal"
          kind="secondary"
          onClick={() => onContinueNoPost()}
        />
      </Flex>
    </>
  );
}

EmailRequestForm.propTypes = {
  userId: PropTypes.number.isRequired,
  csrfToken: PropTypes.string.isRequired,
  onContinue: PropTypes.func.isRequired,
  onContinueNoPost: PropTypes.func.isRequired,
  onFail: PropTypes.func.isRequired,
};

export default EmailRequestForm;
