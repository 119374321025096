import styled from 'styled-components';
import { H2, H4, P, Button } from '@decisiv/ui-components';
import Flex from '@decisiv/ui-components/lib/components/Flex';

export const GreyP = styled.p`
  margin: 15px 0px;
  font-size: 14px;
  color: #737482;
`;
export const InstructionsDiv = styled.div`
  font-size: 14px;
  color: #737482;
  margin-bottom: 20px;
`;
export const Checkmark = styled.img`
  height: 120px;
  width: 120px;
  align-self: center;
  margin-bottom: 10px;
  margin-top: 30px;

  @media (max-width: 768px) {
    height: 80px;
    width: 80px;
  }
`;
export const ResponsiveFlex = styled(Flex)`
  max-width: 600px;

  @media (max-width: 768px) {
    max-width: 390px;
  }
`;

export const Strong = styled.span`
  font-weight: 600;
`;

export const ResponsiveH2 = styled(H2)`
  padding: 0px;
  margin: 50px 0 30px;

  @media screen and (max-width: 768px) {
    margin-top: 20px;
    margin-bottom: 15px;
    padding: 0 20px;
  }
`;

export const ResponsiveH4 = styled(H4)`
  color: #737482 !important;

  @media screen and (max-width: 768px) {
    padding: 0 20px !important;
  }
`;

export const FormInstructionsP = styled(P)`
  font-size: 12px !important;
  font-weight: 400 !important;

  @media screen and (max-width: 768px) {
    font-size: 14px !important;
  }
`;

export const ResponsiveButton = styled(Button)`
  margin-right: 10px;

  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }
`;

export const Padding = styled.div`
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 768px) {
    padding: 0 20px;
  }
`;
