import { useNotifications } from '@decisiv/ui-components';
import { useState } from 'react';
import { postPasswordService } from '../services';

const identityTypes = [
  { label: 'Username', value: 'username' },
  { label: 'Email', value: 'email' },
];

export default function usePasswordReset(csrfToken) {
  const [identity, setIdentity] = useState('');
  const [type, setType] = useState(identityTypes[0].value);
  const [countryCode, setCountryCode] = useState('US');
  const [fetching, setFetching] = useState(false);
  const { notify } = useNotifications();

  async function requestResetPassword() {
    setFetching(true);
    try {
      const response = await postPasswordService(
        csrfToken,
        type,
        identity,
        countryCode,
      );
      setFetching(false);
      await response.json();
      notify({
        id: 'notify-success',
        intent: 'success',
        title:
          'Password reset instructions have been sent to your registered email address or mobile phone.',
      });
    } catch (error) {
      setFetching(false);
      notify({
        id: 'notify-error',
        intent: 'danger',
        title: error,
      });
    }
  }

  return {
    identity,
    setIdentity,
    type,
    setType,
    countryCode,
    setCountryCode,
    requestResetPassword,
    requestingResetPassword: fetching,
    identityTypes,
  };
}
