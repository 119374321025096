import React from 'react';
import { fireEvent, render } from '@testing-library/react';
import Login from './Login';

const props = {
  csrfToken: '123abc',
  dealerId: '12345678',
};

describe('Login', () => {
  describe('Validations', () => {
    it('disables the login button if form is invalid', async () => {
      const { getByRole } = render(<Login {...props} />);

      const button = getByRole('button', { name: 'Login' });

      expect(button).toBeDisabled();
    });

    it('enable the login button if form is valid', async () => {
      const { getByRole, getByLabelText } = render(<Login {...props} />);
      const identityField = getByRole('textbox', {
        name: 'Username, email or mobile phone',
      });
      const passwordField = getByLabelText('Password');

      fireEvent.change(identityField, { target: { value: 'username' } });
      fireEvent.change(passwordField, { target: { value: 'password' } });

      const button = getByRole('button', { name: 'Login' });

      expect(button).toBeEnabled();
    });

    it('should change to Magic Link', async () => {
      const { getByRole, getByLabelText } = render(<Login {...props} />);

      const button = getByRole('button', { name: 'Login with magic link' });

      fireEvent.click(button);

      const identityField = getByLabelText(
        'Mobile Phone Number or Email Address',
      );

      expect(identityField).toBeVisible();
    });
  });

  describe('With Magic Link', () => {
    it('disables the login button if form is invalid', async () => {
      const { getByRole } = render(<Login {...props} />);
      const button = getByRole('button', { name: 'Login with magic link' });
      fireEvent.click(button);

      const sendButton = getByRole('button', { name: 'Request Link' });
      expect(sendButton).toBeDisabled();
    });

    it('enable the login button if form is valid', async () => {
      const { getByRole, getByLabelText } = render(<Login {...props} />);
      const button = getByRole('button', { name: 'Login with magic link' });
      fireEvent.click(button);
      const identityField = getByLabelText(
        'Mobile Phone Number or Email Address',
      );

      const sendButton = getByRole('button', { name: 'Request Link' });

      fireEvent.change(identityField, { target: { value: 'username' } });

      expect(sendButton).toBeEnabled();
    });
  });
});

describe('Request password', () => {
  it('disables request button if form is invalid', async () => {
    const { getByRole } = render(
      <Login defaultScreen="PasswordReset" {...props} />,
    );

    const sendButton = getByRole('button', { name: 'Reset Password' });
    expect(sendButton).toBeDisabled();
  });

  it('enables request button if form is valid', async () => {
    const { getByRole, getAllByLabelText } = render(
      <Login defaultScreen="PasswordReset" {...props} />,
    );

    const identityField = getAllByLabelText('Username').filter(
      (elem) => elem.name === 'identity',
    )[0];

    const sendButton = getByRole('button', { name: 'Reset Password' });

    fireEvent.change(identityField, { target: { value: 'username' } });

    expect(sendButton).toBeEnabled();
  });
});
