import React from 'react';
import PropTypes from 'prop-types';

import { H2, H3, P, Alert } from '@decisiv/ui-components';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import Link from '@decisiv/ui-components/lib/components/Link';
import WarningMessage from 'components/WarningMessage';
import styled from 'styled-components';
import ToggleablePreferenceRow from './ToggleablePreferenceRow';

const InvitationMessageBody = ({ userId }) => (
  <>
    {'Click '}
    <Link
      to={`/users/edit/${userId}#notifications`}
      text="here"
      iconPosition="right"
    />
    {
      ' to access the desktop portal and customize your complete list of notifications.'
    }
  </>
);
InvitationMessageBody.propTypes = {
  userId: PropTypes.string.isRequired,
};

const StyledAlert = styled.div`
  margin-bottom: 20px;

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

function Estimate({ model, kinds, user, smsCommunicationDisabled }) {
  return (
    <div style={{ margin: 'auto -20px' }}>
      <Flex flexDirection="column" style={{ maxWidth: '390px' }}>
        {smsCommunicationDisabled && (
          <StyledAlert>
            <Alert
              intent="warning"
              title="You have texted stop and disabled all communications via SMS in our platform.
                  In order to start receiving text messages with notes and status updates text Start.
                  Once you resume texting capabilities you can opt-in or out from different messages from this page"
            />
          </StyledAlert>
        )}
        <H2 style={{ padding: '0px 20px' }}>Notification Settings</H2>
        <H3 style={{ marginTop: '25px', padding: '0px 20px' }}>
          Notifications
        </H3>
        <P
          style={{
            fontSize: '12px',
            color: '#737482',
            marginTop: '5px',
            padding: '0px 20px',
          }}
        >
          Choose the notifications you want to receive regarding the status of
          your assets when a Case is on going.
        </P>

        {kinds.map((kind, index) => (
          <div
            key={kind.name}
            style={index % 2 !== 0 ? { backgroundColor: '#F1F2F880' } : {}}
          >
            <div style={{ margin: '15px 20px' }}>
              <ToggleablePreferenceRow
                kind={kind}
                user={user}
                model={model}
                smsCommunicationDisabled={smsCommunicationDisabled}
                key={`preference-row-${kind.name}`}
              />
            </div>
          </div>
        ))}

        <div style={{ margin: '0px 20px' }}>
          <WarningMessage
            intent="info"
            title="List of all notification options"
            subtitle={<InvitationMessageBody userId={user.id} />}
          />
        </div>
      </Flex>
    </div>
  );
}

Estimate.propTypes = {
  model: PropTypes.string.isRequired,
  kinds: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      title: PropTypes.string,
      subtitle: PropTypes.string,
      channels: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          title: PropTypes.string,
          value: PropTypes.string,
        }),
      ),
    }),
  ).isRequired,
  user: PropTypes.shape({
    id: PropTypes.string,
    csrfToken: PropTypes.string,
  }).isRequired,
  smsCommunicationDisabled: PropTypes.bool.isRequired,
};

export default Estimate;
