import React from 'react';
import PropTypes from 'prop-types';
import { TextField, Button, Flex, Select } from '@decisiv/ui-components';
import ArrowRight from '@decisiv/iconix/lib/components/ArrowRight';
import { InstructionsDiv, ResponsiveButton, Strong } from './styledComponents';
import useSetMobile from '../hooks/useSetMobile';

function MobileRequestForm({
  userId,
  csrfToken,
  onContinue,
  onContinueNoPost,
  onFail,
  countryCodeOptions,
}) {
  const { setMobile, setCountryCode, postMobile, isDirty } = useSetMobile(
    userId,
    csrfToken,
    onContinue,
    onFail,
  );
  return (
    <>
      <InstructionsDiv>
        Provide a valid mobile phone number to be used in your portal
        communications.
      </InstructionsDiv>
      <Flex marginBottom={1} flexDirection="column">
        <Strong style={{ marginBottom: 10 }}>Mobile Phone Number</Strong>

        <Flex>
          <Select
            style={{
              minWidth: '125px',
              maxWidth: '125px',
              marginRight: '5px',
            }}
            label="Country Code"
            name="Country Code"
            options={countryCodeOptions}
            onChange={(v) => setCountryCode(v)}
          />

          <TextField
            style={{ minWidth: '125px', whiteSpace: 'nowrap' }}
            label="Mobile Phone Number"
            name="Mobile Phone Number"
            type="Phone"
            onChange={(e) => setMobile(e.target.value)}
          />
        </Flex>
      </Flex>
      <Flex marginTop={1}>
        <ResponsiveButton
          icon={ArrowRight}
          text="ADD MOBILE PHONE"
          iconPosition="right"
          disabled={isDirty()}
          onClick={() => postMobile()}
        />
      </Flex>
      <Flex marginTop={1}>
        <Button
          text="Skip and Continue to portal"
          kind="secondary"
          onClick={() => onContinueNoPost()}
        />
      </Flex>
    </>
  );
}

MobileRequestForm.propTypes = {
  userId: PropTypes.number.isRequired,
  csrfToken: PropTypes.string.isRequired,
  onContinue: PropTypes.func.isRequired,
  onContinueNoPost: PropTypes.func.isRequired,
  onFail: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  countryCodeOptions: PropTypes.array.isRequired,
};

export default MobileRequestForm;
