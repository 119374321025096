import React from 'react';
import PropTypes from 'prop-types';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import { TextField, H1, Button } from '@decisiv/ui-components';
import ArrowRight from '@decisiv/iconix/lib/components/ArrowRight';

const propTypes = {
  dealerId: PropTypes.string,
  csrfToken: PropTypes.string.isRequired,
};

const defaultProps = {
  dealerId: '',
  backLoginClick: () => {},
};

const InputField = (props) => {
  return (
    <Flex marginBottom={1}>
      <TextField {...props} />
    </Flex>
  );
};

const HelperText = ({ fields, message }) => {
  const warning = fields ? `Please provide the ${fields} associated with your account:` : message

  return (
    <p style={{ margin: '15px 0px' }}>
      {warning}
    </p>
  );
};

HelperText.propTypes = {
  fields: PropTypes.string,
  message: PropTypes.string
};

const Container = ({ children, title }) => {
  return (
    <Flex
      justifyContent="center"
      alignItems="center"
      style={{ marginTop: '-1px' }}
    >
      <Flex
        flexDirection="column"
        style={{ maxWidth: '600px', margin: '0 20px'}}
      >
        <H1 style={{ padding: '0px', margin: '0px' }}>{title}</H1>
        {children}
      </Flex>
    </Flex>
  );
};

Container.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

const RequestForm = ({
  backAction,
  submitAction,
  onChange,
  isDirty,
  children,
  type,
}) => {
  const labels = (() => {
    const byType = {
      magic_link: {
        title: 'Request magic link',
        fields: 'Mobile Phone Number or Email Address',
        button: 'Request Link',
      },
      request_password: {
        title: 'Reset Password',
        fields: 'Username or Email Address',
        button: 'Reset Password',
      },
    };

    if (Object.keys(byType).indexOf(type) === -1)
      throw new Error(`Can't find labels for type ${type}`);

    return byType[type];
  })();

  return (
    <div>
      <Container title={labels.title}>
        <HelperText fields={labels.fields} />

        <InputField label={labels.fields} name="identity" onChange={onChange} />
        <Flex>
          <Button
            icon={ArrowRight}
            text={labels.button}
            iconPosition="right"
            onClick={() => submitAction()}
            disabled={isDirty}
          />
        </Flex>
        <Flex marginTop={1}>
          <Button
            text="Back to Login"
            kind="secondary"
            onClick={() => backAction()}
            style={{ marginRight: '15px' }}
          />
        </Flex>
        {children}
      </Container>
    </div>
  );
};

RequestForm.propTypes = {
  backAction: PropTypes.func.isRequired,
  submitAction: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  isDirty: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  children: PropTypes.node,
};

RequestForm.defaultProps = {
  children: <React.Fragment />,
};

export default RequestForm;
export { propTypes, defaultProps, RequestForm, InputField, Container, HelperText };
