import React from 'react';
import PropTypes from 'prop-types';

import {
  Button,
  H2,
  P,
  NotificationsPanel,
  Flex,
  PasswordField,
} from '@decisiv/ui-components';
import toColorString from 'polished/lib/color/toColorString';
import color from '@decisiv/design-tokens/lib/color';
import useSetPassword from './hooks/useSetPassword';

function ExtraInfo() {
  return (
    <P
      weight="regular"
      shade={1}
      style={{ fontSize: '12px', textAlign: 'center', marginTop: '0px' }}
    >
      By clicking Set up My Password, you agree to our
      <a href="https://support.dsmp.decisiv.net/hc/en-us/articles/360011094554">
        {' '}
        Terms & Conditions
      </a>{' '}
      and that you have read our
      <a href="https://support.dsmp.decisiv.net/hc/en-us/articles/360011094554">
        {' '}
        Privacy Policy
      </a>
      .
    </P>
  );
}
function PasswordDetails() {
  return (
    <P style={{ fontSize: '14px', color: '#3E3F4D', marginTop: '25px' }}>
      <span style={{ fontWeight: '600' }}>Password Requirements:</span>
      <ul>
        <li>At least 8 characters—the more characters, the better.</li>
        <li>A mixture of both uppercase and lowercase letters.</li>
        <li>A mixture of letters and numbers.</li>
      </ul>
    </P>
  );
}

function PasswordRequest({ userId, csrfToken, portalName, targetUrl }) {
  const onSuccess = () => {
    window.location = targetUrl;
  };

  const {
    setPassword,
    postPassword,
    setCheckPassword,
    isDirty,
  } = useSetPassword(userId, csrfToken, onSuccess);

  return (
    <div>
      <NotificationsPanel marginTop={4} zIndex={1000} />
      <Flex
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: '80vh' }}
      >
        <Flex flexDirection="column" style={{ maxWidth: '390px' }}>
          <H2 style={{ padding: '0px', margin: '0px' }}>
            Welcome to <span style={{ fontWeight: '600' }}>{portalName}</span>
          </H2>
          <p
            weight="regular"
            shade={1}
            style={{ margin: '15px 0px', fontSize: '14px', color: '#737482' }}
          >
            Set a password to your account to access{' '}
            <span style={{ fontWeight: '600' }}>{portalName}</span>.
          </p>
          <Flex
            flexDirection="column"
            style={{ backgroundColor: toColorString(color.opacity.fullMoon50) }}
            padding={2}
          >
            <PasswordField
              style={{ marginBottom: '10px' }}
              // label="Password"
              name="password"
              required
              onChange={(e) => setPassword(e.target.value)}
            />
            <PasswordField
              // label="Confirm Password"
              name="confirmPassword"
              required
              onChange={(e) => setCheckPassword(e.target.value)}
            />
            <Flex justifyContent="space-between" marginTop={1}>
              <Button
                text="Set up my password"
                kind="primary"
                disabled={isDirty()}
                onClick={() => postPassword()}
              />
            </Flex>
            <PasswordDetails />
            <ExtraInfo />
          </Flex>
        </Flex>
      </Flex>
    </div>
  );
}

PasswordRequest.propTypes = {
  userId: PropTypes.number.isRequired,
  csrfToken: PropTypes.string.isRequired,
  portalName: PropTypes.string.isRequired,
  targetUrl: PropTypes.string.isRequired,
};

export default PasswordRequest;
