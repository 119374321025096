import React from 'react';
import PropTypes from 'prop-types';
import { Button, H4, Flex, P } from '@decisiv/ui-components';
import ArrowRight from '@decisiv/iconix/lib/components/ArrowRight';
import checkmark from '../../../../../assets/images/checkmark.png';
import { Checkmark, GreyP, Strong, Padding } from './styledComponents';
import { REQUEST_EMAIL } from '../constants';

function WelcomeScreen({ requestType, onContinue, portalName, realUser }) {
  const requestText =
    requestType === REQUEST_EMAIL ? 'email' : 'mobile phone number';
  const noRequestNeeded = requestType === 'ok';

  return (
    <Padding>
      <Checkmark src={checkmark} />
      {noRequestNeeded && (
        <H4>
          Your account was successfully associated with{' '}
          <Strong>{portalName}</Strong>.
        </H4>
      )}

      {!noRequestNeeded && (
        <>
          <GreyP>
            Your {requestText} was successfully added to your account.
          </GreyP>
          <GreyP>
            You will now start receiving updates through text messages on your{' '}
            {requestText}.
          </GreyP>
        </>
      )}
      <Flex style={{ marginTop: 10 }}>
        {onContinue && (
          <Button
            icon={ArrowRight}
            text="GO TO CASE"
            iconPosition="right"
            onClick={() => onContinue()}
          />
        )}
        {!onContinue && (
          <P shade={1} paddingTop={2}>
            There are no communications available yet. When communications are
            available you will be directed to the service event (Case page).
          </P>
        )}
      </Flex>
      {realUser && (
        <Flex marginTop={4}>
          <a href="/mobilecomm/users/edit_current">
            REVIEW MY NOTIFICATION PREFERENCES
          </a>
        </Flex>
      )}
    </Padding>
  );
}

WelcomeScreen.propTypes = {
  requestType: PropTypes.string,
  onContinue: PropTypes.func,
  portalName: PropTypes.string,
  realUser: PropTypes.bool,
};

WelcomeScreen.defaultProps = {
  requestType: '',
  onContinue: null,
  portalName: '',
  realUser: false,
};

export default WelcomeScreen;
