import React from 'react';
import BreakpointObserver, { sizes } from '@decisiv/breakpoint-observer';
import Flex from '@decisiv/ui-components/lib/components/Flex';
import Select from '@decisiv/ui-components/lib/components/Select';
import { P } from '@decisiv/ui-components/lib/components/Typography';
import TextField from '@decisiv/ui-components/lib/components/TextField';
import Button from '@decisiv/ui-components/lib/components/Button';
import RadioGroup from '@decisiv/ui-components/lib/components/RadioGroup';
import ArrowRight from '@decisiv/iconix/lib/components/ArrowRight';
import styled from 'styled-components';
import { H1 } from '@decisiv/ui-components';
import usePasswordReset from './hooks/usePasswordReset';
import { propTypes, defaultProps } from './RequestForm';

const countryCodes = [
  { id: 'CA', label: 'CAN +1', value: '1' },
  { id: 'CL', label: 'CHL +56', value: '56' },
  { id: 'MX', label: 'MEX +52', value: '52' },
  { id: 'US', label: 'USA +1', value: '1' },
];

const identityTypeLabels = {
  username: 'Username',
  email: 'Email',
  mobile: 'Mobile Phone Number',
};

const ButtonsContainer = styled(Flex)`
  margin-top: 5px;

  @media screen and (max-width: 390px) {
    flex-direction: column-reverse;

    .btn__element--login-btn {
      margin-bottom: 15px;
    }
  }
`;

const PasswordResetContainer = styled(Flex)`
  justify-content: center;
  margin: 0 auto;
  width: 600px;
  flex-direction: column;

  @media screen and (max-width: 600px) {
    width: 350px;
  }
`;

function PasswordReset({ csrfToken, backLoginClick }) {
  // The breakpoints object defines the minimum widths for specific breakpoint names.
  const breakpoints = {
    [sizes.SM]: 300,
    [sizes.MD]: 600,
  };

  const {
    type,
    setType,
    identity,
    setIdentity,
    countryCode,
    setCountryCode,
    identityTypes,
    requestResetPassword,
    requestingResetPassword,
  } = usePasswordReset(csrfToken);

  return (
    <BreakpointObserver breakpoints={breakpoints}>
      {({
        breakpoint, // The currently active breakpoint. Defaults to `sizes.XS`.
        dimensions, // An object containing the `height` & `width` of the observed DOM element.
        observedElementRef, // The ref must be assigned to the DOM element you want to observe.
      }) => (
        <div ref={observedElementRef}>
          <PasswordResetContainer>
            <Flex flexDirection="column" style={{ maxWidth: '390px' }}>
              <H1 style={{ padding: '0px', margin: '0px' }}>Reset Password</H1>
            </Flex>
            <P color="alaskanHusky">
              Please provide one of the following associated with your account
              to reset your password.
            </P>

            <Flex flexDirection="column" marginBottom={1}>
              <P size="small" color="alaskanHusky">
                Reset password using:
              </P>
              <RadioGroup
                vertical={breakpoint === 'XS' || breakpoint === 'SM'}
                name="type"
                size="small"
                items={identityTypes}
                value={type}
                onChange={(e) => {
                  setType(e.target.value);
                  setIdentity('');
                }}
              />
            </Flex>

            <Flex flexDirection="row">
              {type === 'mobile' && (
                <Flex style={{ width: '120px' }} marginRight={0.5}>
                  <Select
                    label="Country Code"
                    name="countryCode"
                    options={countryCodes}
                    onChange={(e) => {
                      setCountryCode(e || 'US');
                    }}
                    value={countryCode}
                  />
                </Flex>
              )}
              <Flex flex={1}>
                <TextField
                  style={{ width: '100%' }}
                  label={identityTypeLabels[type]}
                  name="identity"
                  value={identity}
                  onChange={(e) => {
                    setIdentity(e.target.value);
                  }}
                />
              </Flex>
            </Flex>

            <ButtonsContainer>
              <Flex marginRight={1.5} marginTop={1}>
                <Button
                  text="Back to Login"
                  kind="secondary"
                  onClick={backLoginClick}
                />
              </Flex>
              <Flex marginTop={1}>
                <Button
                  icon={ArrowRight}
                  text="Reset Password"
                  iconPosition="right"
                  onClick={() => {
                    requestResetPassword();
                    setIdentity('');
                  }}
                  disabled={!identity.trim() || requestingResetPassword}
                />
              </Flex>
            </ButtonsContainer>
          </PasswordResetContainer>
        </div>
      )}
    </BreakpointObserver>
  );
}

PasswordReset.propTypes = propTypes;
PasswordReset.defaultProps = defaultProps;

export default PasswordReset;
