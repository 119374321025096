import { useState } from 'react';
import { postSetEmail } from '../services';

function useSetEmail(userId, csrfToken, sucessCallback, failCallback) {
  const [email, setEmail] = useState('');

  async function postEmail() {
    try {
      const response = await postSetEmail(userId, csrfToken, email);

      await response.json();
      sucessCallback();
    } catch (error) {
      failCallback(email);
    }
  }

  function isDirty() {
    return !email;
  }

  return {
    email,
    setEmail,
    postEmail,
    isDirty,
  };
}

export default useSetEmail;
